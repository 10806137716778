import {
    List,
    labelClassName,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import type { ListItemProps } from '@archipro-design/aria';
import { DownloadLine } from '@archipro-design/icons';

import { useLoaderData } from '@remix-run/react';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';

import PageSection from '@modules/professional/component/page-layout/PageSection';
import * as S from './Downloads.style';
import {
    TRACK_DOWNLOAD_CLASSNAME,
    DOWNLOAD_ATTR_FILE_TITLE,
    DOWNLOAD_ATTR_PROFESSIONAL_TITLE,
    DOWNLOAD_ATTR_PROFESSIONAL_ID,
    DOWNLOAD_ATTR_FILE_ID,
} from '@archipro-website/tracker';

export const Downloads: React.FC = () => {
    const { downloads, professionalOverview: professional } =
        useLoaderData<OverviewLoaderData>();
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();

    if (!downloads?.length) {
        return null;
    }

    const items = downloads
        .map((d) => ({
            label: d.Title,
            link: d.ExternalLink ?? d.InternalLink + '',
            iconBefore: <DownloadLine size="1x" />,
            openNewWindow: true,
            iconPadding: 40,
            linkClassName: TRACK_DOWNLOAD_CLASSNAME,
            linkAttributes: {
                [DOWNLOAD_ATTR_PROFESSIONAL_TITLE]: professional.Title,
                [DOWNLOAD_ATTR_FILE_TITLE]: d.Title,
                [DOWNLOAD_ATTR_PROFESSIONAL_ID]: professional.ID,
                [DOWNLOAD_ATTR_FILE_ID]: d.ID,
            },
        }))
        .filter((i) => i.link !== 'null') as ListItemProps[];

    if (items.length === 0) {
        return null;
    }

    return (
        <div>
            <PageSection
                sectionTitle="Downloads"
                style={
                    mobile
                        ? {
                              [`& .${labelClassName}`]: {
                                  marginBottom: 0,
                              },
                          }
                        : undefined
                }
            >
                <List items={items} className={css(S.DownloadList)} />
            </PageSection>
        </div>
    );
};
